import { map } from 'lodash-es';
import { groupBy } from 'lodash-es';

export enum AcademyPermissions {
	// Icons
	ReadIcons = 'ReadIcons',
	CreateIcons = 'CreateIcons',
	ModifyIcons = 'ModifyIcons',
	RemoveIcons = 'RemoveIcons',

	// Tags
	ReadTags = 'ReadTags',
	CreateTags = 'CreateTags',
	ModifyTags = 'ModifyTags',
	RemoveTags = 'RemoveTags',

	CreateTagScopes = 'CreateTagScopes',
	ModifyTagScopes = 'ModifyTagScopes',
	RemoveTagScopes = 'RemoveTagScopes',

	// Questions
	ReadQuestionFolders = 'ReadQuestionFolders',
	CreateQuestionFolders = 'CreateQuestionFolders',
	ModifyQuestionFolders = 'ModifyQuestionFolders',
	RemoveQuestionFolders = 'RemoveQuestionFolders',

	ReadQuestions = 'ReadQuestions',
	ReadArchivedQuestions = 'ReadArchivedQuestions',
	ReadArchivedQuestion = 'ReadArchivedQuestion',
	CreateQuestions = 'CreateQuestions',
	ModifyQuestions = 'ModifyQuestions',
	RemoveQuestions = 'RemoveQuestions',

	// Staging
	AccessModuleStaging = 'AccessModuleStaging',
	ReadStagingModules = 'ReadStagingModules',
	CreateStagingModules = 'CreateStagingModules',
	ModifyStagingModules = 'ModifyStagingModules',
	CreateStagingReplacementModules = 'CreateStagingReplacementModules',
	RemoveStagingModules = 'RemoveStagingModules',
	RequestStagingModuleReviews = 'RequestStagingModuleReviews',
	ApproveStagingModules = 'ApproveStagingModules',

	// Modules
	ReadModuleFolders = 'ReadModuleFolders',
	CreateModuleFolders = 'CreateModuleFolders',
	ModifyModuleFolders = 'ModifyModuleFolders',
	RemoveModuleFolders = 'RemoveModuleFolders',

	ReadModules = 'ReadModules',
	ReadArchivedModules = 'ReadArchivedModules',
	ReadArchivedModule = 'ReadArchivedModule',
	ReadPublishedModules = 'ReadPublishedModules',
	ReadSharedModules = 'ReadSharedModules',
	ReadModuleChanges = 'ReadModuleChanges',
	CreateModules = 'CreateModules',
	ModifyModules = 'ModifyModules',
	RemoveModules = 'RemoveModules',
	PublishModules = 'PublishModules',
	ShareModules = 'ShareModules',
	TestModules = 'TestModules',

	DownloadAllMaterials = 'DownloadAllMaterials',

	// Tracks
	ReadTracks = 'ReadTracks',
	CreateTracks = 'CreateTracks',
	ModifyTracks = 'ModifyTracks',
	RemoveTracks = 'RemoveTracks',

	// Courses
	ReadCourses = 'ReadCourses',
	CreateCourses = 'CreateCourses',
	ModifyCourses = 'ModifyCourses',
	RemoveCourses = 'RemoveCourses',
	PublishCourses = 'PublishCourses',
	TestCourses = 'TestCourses',

	// Course Modules
	ReadCourseModules = 'ReadCourseModules',
	CreateCourseModules = 'CreateCourseModules',
	ModifyCourseModules = 'ModifyCourseModules',
	RemoveCourseModules = 'RemoveCourseModules',
}

export enum AcademyPermissionCategory {
	Icons = 'Icons',
	Tags = 'Tags',
	Questions = 'Questions',
	Staging = 'Staging',
	Modules = 'Modules',
	Tracks = 'Tracks',
	Courses = 'Courses',
	CourseModules = 'Course Modules',
}

export enum AcademyPermissionType {
	Read = 'Read',
	Download = 'Download',
	Create = 'Create',
	Modify = 'Modify',
	Remove = 'Remove',
	Test = 'Test',
	Misc = '',
}

export interface AcademyPermissionData {
	name: string;
	category: AcademyPermissionCategory;
	description: string;
	event: boolean;
	icon?: string;
	id?: AcademyPermissions;
}

const academyPermissionDataDefinition: {
	[key in keyof typeof AcademyPermissions]: AcademyPermissionData;
} = {
	// Icons
	[AcademyPermissions.ReadIcons]: {
		name: 'Read Icons',
		category: AcademyPermissionCategory.Icons,
		description: 'Allows user to load Icons',
		event: false,
	},
	[AcademyPermissions.CreateIcons]: {
		name: 'Create Icons',
		category: AcademyPermissionCategory.Icons,
		description: 'Allows user to Create new Icons',
		event: false,
	},
	[AcademyPermissions.ModifyIcons]: {
		name: 'Modify Icons',
		category: AcademyPermissionCategory.Icons,
		description: 'Allows user to Edit existing Icons',
		event: false,
	},
	[AcademyPermissions.RemoveIcons]: {
		name: 'Remove Icons',
		category: AcademyPermissionCategory.Icons,
		description: 'Allows user to Archive existing Icons',
		event: false,
	},

	// Tags
	[AcademyPermissions.ReadTags]: {
		name: 'Read Tags',
		category: AcademyPermissionCategory.Tags,
		description: 'Allows user to Load Tags',
		event: false,
	},
	[AcademyPermissions.CreateTags]: {
		name: 'Create Tags',
		category: AcademyPermissionCategory.Tags,
		description: 'Allows user to Create new Tags',
		event: false,
	},
	[AcademyPermissions.ModifyTags]: {
		name: 'Modify Tags',
		category: AcademyPermissionCategory.Tags,
		description: 'Allows user to Edit existing Tags',
		event: false,
	},
	[AcademyPermissions.RemoveTags]: {
		name: 'Remove Tags',
		category: AcademyPermissionCategory.Tags,
		description: 'Allows user to ',
		event: false,
	},

	[AcademyPermissions.CreateTagScopes]: {
		name: 'Create Tag Scopes',
		category: AcademyPermissionCategory.Tags,
		description: 'Allows user to Create Tag Scopes',
		event: false,
	},
	[AcademyPermissions.ModifyTagScopes]: {
		name: 'Modify Tag Scopes',
		category: AcademyPermissionCategory.Tags,
		description: 'Allows user to Edit existing Tag Scopes',
		event: false,
	},
	[AcademyPermissions.RemoveTagScopes]: {
		name: 'Remove Tag Scopes',
		category: AcademyPermissionCategory.Tags,
		description: 'Allows user to Archive existing Tag Scopes',
		event: false,
	},

	// Questions
	[AcademyPermissions.ReadQuestionFolders]: {
		name: 'Read Question Folders',
		category: AcademyPermissionCategory.Questions,
		description: 'Allows user to Load Question Folders',
		event: false,
	},
	[AcademyPermissions.CreateQuestionFolders]: {
		name: 'Create Question Folders',
		category: AcademyPermissionCategory.Questions,
		description: 'Allows user to Create a new Question Folder',
		event: false,
	},
	[AcademyPermissions.ModifyQuestionFolders]: {
		name: 'Modify Question Folders',
		category: AcademyPermissionCategory.Questions,
		description: 'Allows user to Edit existing Question Folders',
		event: false,
	},
	[AcademyPermissions.RemoveQuestionFolders]: {
		name: 'Remove Question Folders',
		category: AcademyPermissionCategory.Questions,
		description:
			'Allows user to Archive existing Question Folders (When archiving Folders containing Questions it is required to also have the "Remove Questions" permission)',
		event: false,
	},

	[AcademyPermissions.ReadQuestions]: {
		name: 'Read Questions',
		category: AcademyPermissionCategory.Questions,
		description:
			'Allows user to Load Questions (Depends on "Read Question Folders")',
		event: false,
	},
	[AcademyPermissions.ReadArchivedQuestions]: {
		name: 'Read All Archived Questions',
		category: AcademyPermissionCategory.Questions,
		description: 'Allows user to Load archived Questions',
		event: false,
	},
	[AcademyPermissions.ReadArchivedQuestion]: {
		name: 'Read Individual Archived Questions',
		category: AcademyPermissionCategory.Questions,
		description:
			'Allows user to Load the Read Only version of archived Questions (Not needed if they have "Read All Archived Question" permission)',
		event: false,
	},
	[AcademyPermissions.CreateQuestions]: {
		name: 'Create Questions',
		category: AcademyPermissionCategory.Questions,
		description: 'Allows user to Create a new Question',
		event: false,
	},
	[AcademyPermissions.ModifyQuestions]: {
		name: 'Modify Questions',
		category: AcademyPermissionCategory.Questions,
		description: 'Allows user to Edit an existing Question',
		event: false,
	},
	[AcademyPermissions.RemoveQuestions]: {
		name: 'Remove Questions',
		category: AcademyPermissionCategory.Questions,
		description: 'Allows user to Archive an existing Question',
		event: false,
	},

	// Staging
	[AcademyPermissions.AccessModuleStaging]: {
		name: 'Access Module Staging',
		category: AcademyPermissionCategory.Staging,
		description:
			'Allows user to open the Staging Page (This also allows Content Owners / Module Creators to load and edit their own Modules)',
		event: false,
	},
	[AcademyPermissions.ReadStagingModules]: {
		name: 'Read Staging Modules for Review',
		category: AcademyPermissionCategory.Staging,
		description:
			'Allows user to Load all Staging Modules that have been put up for review',
		event: false,
	},
	[AcademyPermissions.CreateStagingModules]: {
		name: 'Create Staging Modules',
		category: AcademyPermissionCategory.Staging,
		description: 'Allows user to Create a new Staging Module',
		event: false,
	},
	[AcademyPermissions.ModifyStagingModules]: {
		name: 'Modify Staging Modules',
		category: AcademyPermissionCategory.Staging,
		description:
			'Allows user to Modify all Staging Modules that they have read permission for (There are some properties that are only editable with this permission)',
		event: false,
	},
	[AcademyPermissions.CreateStagingReplacementModules]: {
		name: 'Create Staging Replacement Modules',
		category: AcademyPermissionCategory.Staging,
		description:
			'Allows user to Pick a Module that the Staging Module should replace',
		event: false,
	},
	[AcademyPermissions.RemoveStagingModules]: {
		name: 'Remove Staging Modules',
		category: AcademyPermissionCategory.Staging,
		description: 'Allows user to Archive Staging Modules',
		event: false,
	},
	[AcademyPermissions.RequestStagingModuleReviews]: {
		name: 'Request Staging Module Reviews',
		category: AcademyPermissionCategory.Staging,
		description: 'Allows user to send a Staging Module to Review',
		event: false,
	},
	[AcademyPermissions.ApproveStagingModules]: {
		name: 'Approve Staging Modules',
		category: AcademyPermissionCategory.Staging,
		description:
			'Allows user to Approve a Staging Module and send it out of the Staging Environment (Note that when approving modules marked for replacing, the user also needs permissions for replacing - "Archive Module")',
		event: false,
	},

	// Modules
	[AcademyPermissions.ReadModuleFolders]: {
		name: 'Read Module Folders',
		category: AcademyPermissionCategory.Modules,
		description: 'Allows user to Load Module Folders',
		event: false,
	},
	[AcademyPermissions.CreateModuleFolders]: {
		name: 'Create Module Folders',
		category: AcademyPermissionCategory.Modules,
		description: 'Allows user to Create a new Module Folder',
		event: false,
	},
	[AcademyPermissions.ModifyModuleFolders]: {
		name: 'Modify Module Folders',
		category: AcademyPermissionCategory.Modules,
		description: 'Allows user to Edit Existing Module Folders',
		event: false,
	},
	[AcademyPermissions.RemoveModuleFolders]: {
		name: 'Remove Module Folders',
		category: AcademyPermissionCategory.Modules,
		description:
			'Allows user to Archive Module Folders (When archiving Folders containing Modules it is required to also have the "Remove Modules" permission)',
		event: false,
	},

	[AcademyPermissions.ReadModules]: {
		name: 'Read All Modules',
		category: AcademyPermissionCategory.Modules,
		description: 'Allows user to Load all (Unarchived) Modules',
		event: false,
	},
	[AcademyPermissions.ReadArchivedModules]: {
		name: 'Read All Archived Modules',
		category: AcademyPermissionCategory.Modules,
		description: 'Allows user to Load archived Modules',
		event: false,
	},
	[AcademyPermissions.ReadArchivedModule]: {
		name: 'Read Specific Archived Module',
		category: AcademyPermissionCategory.Modules,
		description:
			'Allows user to Load the Read Only version of archived Modules (Not needed if they have "Read All Archived Modules" permission)',
		event: false,
	},
	[AcademyPermissions.ReadPublishedModules]: {
		name: 'Read Published Modules',
		category: AcademyPermissionCategory.Modules,
		description: 'Allows user to Load Modules that are Published',
		event: false,
	},
	[AcademyPermissions.ReadSharedModules]: {
		name: 'Read Shared Modules',
		category: AcademyPermissionCategory.Modules,
		description: 'Allows user to Load Modules that are Shared',
		event: false,
	},
	[AcademyPermissions.ReadModuleChanges]: {
		name: 'Read Module Changes',
		category: AcademyPermissionCategory.Modules,
		description:
			'Allows user to Fetch a list of changes made to a Module while in the Read Only view',
		event: false,
	},
	[AcademyPermissions.CreateModules]: {
		name: 'Create Modules',
		category: AcademyPermissionCategory.Modules,
		description: 'Allows user to Create a new Module',
		event: false,
	},
	[AcademyPermissions.ModifyModules]: {
		name: 'Modify Modules',
		category: AcademyPermissionCategory.Modules,
		description: 'Allows user to Edit an existing Module',
		event: false,
	},
	[AcademyPermissions.RemoveModules]: {
		name: 'Remove Modules',
		category: AcademyPermissionCategory.Modules,
		description: 'Allows user to Archive an existing Module',
		event: false,
	},
	[AcademyPermissions.PublishModules]: {
		name: 'Publish Modules',
		category: AcademyPermissionCategory.Modules,
		description: 'Allows user to Publish a Module',
		event: false,
	},
	[AcademyPermissions.ShareModules]: {
		name: 'Share Modules',
		category: AcademyPermissionCategory.Modules,
		description: 'Allows user to Share a Module',
		event: false,
	},
	[AcademyPermissions.TestModules]: {
		name: 'Test Modules',
		category: AcademyPermissionCategory.Modules,
		description:
			'Allows user to Access, and interact with Modules in the Testing Phase',
		event: false,
	},

	[AcademyPermissions.DownloadAllMaterials]: {
		name: 'Download All Materials',
		category: AcademyPermissionCategory.Modules,
		description: 'Allows user to download all materials',
		event: false,
	},

	// Tracks
	[AcademyPermissions.ReadTracks]: {
		name: 'Read Tracks',
		category: AcademyPermissionCategory.Tracks,
		description: 'Allows user to Load all Tracks',
		event: true,
	},
	[AcademyPermissions.CreateTracks]: {
		name: 'Create Tracks',
		category: AcademyPermissionCategory.Tracks,
		description: 'Allows user to Create a new Track',
		event: true,
	},
	[AcademyPermissions.ModifyTracks]: {
		name: 'Modify Tracks',
		category: AcademyPermissionCategory.Tracks,
		description: 'Allows user to Edit existing Tracks',
		event: true,
	},
	[AcademyPermissions.RemoveTracks]: {
		name: 'Remove Tracks',
		category: AcademyPermissionCategory.Tracks,
		description: 'Allows user to Archive an existing Track',
		event: true,
	},

	// Courses
	[AcademyPermissions.ReadCourses]: {
		name: 'Read Courses',
		category: AcademyPermissionCategory.Courses,
		description: 'Allows user to Load Courses',
		event: true,
	},
	[AcademyPermissions.CreateCourses]: {
		name: 'Create Courses',
		category: AcademyPermissionCategory.Courses,
		description: 'Allows user to Create a new Course',
		event: true,
	},
	[AcademyPermissions.ModifyCourses]: {
		name: 'Modify Courses',
		category: AcademyPermissionCategory.Courses,
		description: 'Allows user to Edit existing Courses',
		event: true,
	},
	[AcademyPermissions.RemoveCourses]: {
		name: 'Remove Courses',
		category: AcademyPermissionCategory.Courses,
		description: 'Allows user to Archive an existing Course',
		event: true,
	},
	[AcademyPermissions.PublishCourses]: {
		name: 'Publish Courses',
		category: AcademyPermissionCategory.Courses,
		description: 'Allows user to Publish a Course for uses to see',
		event: true,
	},
	[AcademyPermissions.TestCourses]: {
		name: 'Test Courses',
		category: AcademyPermissionCategory.Courses,
		description:
			'Allows user to Access and interact with Modules in the Course while in the Testing Phase',
		event: true,
	},

	// Course Modules
	[AcademyPermissions.ReadCourseModules]: {
		name: 'Read Course Modules',
		category: AcademyPermissionCategory.CourseModules,
		description: 'Allows user to Load Course Modules',
		event: true,
	},
	[AcademyPermissions.CreateCourseModules]: {
		name: 'Create Course Modules',
		category: AcademyPermissionCategory.CourseModules,
		description: 'Allows user to Create a new Course Module',
		event: true,
	},
	[AcademyPermissions.ModifyCourseModules]: {
		name: 'Modify Course Modules',
		category: AcademyPermissionCategory.CourseModules,
		description: 'Allows user to Edit an existing Course Module',
		event: true,
	},
	[AcademyPermissions.RemoveCourseModules]: {
		name: 'Remove Course Modules',
		category: AcademyPermissionCategory.CourseModules,
		description: 'Allows user to Archive an existing Course Module',
		event: true,
	},
};

export const academyPermissionData = map(
	academyPermissionDataDefinition,
	(data, id: AcademyPermissions) =>
		({
			...data,
			id,
			icon: data.icon ?? getPermissionIcon(id),
		} as AcademyPermissionData)
);

export interface AcademyPermissionLoadModel {
	client: AcademyPermissions[];
	event?: AcademyPermissions[];
	roles: AcademyRole[];
}

export interface AcademyRole {
	id: string;
	name: string;
	guideUrl: string | null;
}

export interface AcademyPermissionCategoryData {
	category: AcademyPermissionCategory;
	permissions: AcademyPermissionData[];
}

function getPermissionIcon(permission: AcademyPermissions) {
	let type = AcademyPermissionType.Misc;

	for (const t of Object.values(AcademyPermissionType)) {
		if (!permission.startsWith(t)) {
			continue;
		}

		type = t;
		break;
	}

	switch (type) {
		case AcademyPermissionType.Read:
			return 'fa-cloud-download-alt';
		case AcademyPermissionType.Download:
			return 'fa-download';
		case AcademyPermissionType.Create:
			return 'fa-plus-circle';
		case AcademyPermissionType.Modify:
			return 'fa-edit';
		case AcademyPermissionType.Remove:
			return 'fa-trash-alt';
		case AcademyPermissionType.Test:
			return 'fa-vial';
		case AcademyPermissionType.Misc:
			return 'fa-percentage';
	}
}

export function getAcademyPermissionCategoryData(
	permissions: AcademyPermissionData[]
) {
	const sort = groupBy(permissions, x => x.category ?? '');

	return map(
		sort,
		(permissions, category) =>
			({ category, permissions } as AcademyPermissionCategoryData)
	).filter(x => x.permissions.length);
}
